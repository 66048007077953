"use client";

import React, { useState } from "react";
import { Button } from "./_components/ui/buttons/button";

export default function GlobalError({
  error,
}: {
  error: Error & { digest?: string };
}) {
  const [copySuccess, setCopySuccess] = useState("");

  const handleCopyClick = () => {
    // Copy error details to clipboard
    navigator.clipboard.writeText(`${error.toString()}\n${error.stack}`).then(
      () => {
        setCopySuccess("✓ Error Crace Copied");
      },
      () => {
        setCopySuccess("Failed to Copy Error Trace");
      },
    );
  };

  return (
    <div className="min-h-screen bg-neutral-200 flex items-center justify-center p-6">
      <div className="max-w-md w-full bg-white shadow-md rounded px-6 pt-6 pb-8 mb-4">
        <h2 className="text-xl mb-8 text-dark text-center">
          Something went wrong
        </h2>
        <div className="mb-4 overflow-auto bg-neutral-100 p-3 border border-neutral-300 rounded max-h-64">
          {error.toString()}
          <pre className="whitespace-pre-wrap text-sm">{error.stack}</pre>
        </div>
        <div className="flex gap-2 justify-center">
          <Button variant="primary" onClick={handleCopyClick}>
            {copySuccess ? copySuccess : "Copy Error Trace"}
          </Button>
          <Button
            onClick={() => {
              localStorage.clear();
              window.location.reload();
            }}
            variant="secondary"
          >
            Reload Page
          </Button>
        </div>
        <p className="mt-4 text-sm text-neutral-700 px-4 text-center">
          Please provide the copied error information to our support team for
          further assistance.
        </p>
      </div>
    </div>
  );
}
